import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
// ---------------------------------------------
import { CloseIcon } from "../components/svgs"
import Header from "../components/header"
import Layout from "../components/layout"
import LightboxLink from "../components/lightbox/link"
import Picture from "../components/picture"
import Vimeo from "../components/vimeo"
import Splash from "../components/splash"
import Transition from "../components/transition"
import SEO from "../components/seo"

function getUniqueById(array) {
  let dictionary = new Map()

  return array.filter(item => {
    if (!dictionary.has(item.slug)) {
      dictionary.set(item.slug, true)
      return true
    } else {
      return false
    }
  })
}

function typeFilters(projects) {
  return getUniqueById(
    [].concat.apply(
      [],
      projects.map(project => project.projectTypes)
    )
  )
}

const ArtistBio = ({ title, biography, handleCloseClick }) => (
  <div className="bio">
    <div className="bio__content">
      <div>
        <div>
          <span className="paragraph uppercase">{title},&nbsp;</span>
          <span className="heading--4">Biography</span>
        </div>
        <div
          className="bio__markdown"
          dangerouslySetInnerHTML={{ __html: biography }}
        />
      </div>
    </div>
    <button className="bio__close" onClick={handleCloseClick}>
      <CloseIcon />
    </button>
  </div>
)

const ArtistPage = ({ data, location, pageContext }) => {
  const { categories, projects, artist } = data.craft
  const { title, slug, biography, instagramUrl, artistAgentEmail } = artist[0]
  const artistSlug = slug
  const exisitingCategories = typeFilters(projects)
  const [activeFilter, setActiveFilter] = useState(pageContext.filter)
  const [highlight, setHighlight] = useState(false)
  const [bioOpen, setBioOpen] = useState(false)

  const resetHighlight = () => {
    setHighlight(false)
  }

  const handleFilterClick = newFilter => {
    setActiveFilter(newFilter)
    window.history.pushState(null, null, `/${artist[0].slug}/${newFilter}`)
  }

  useEffect(() => {
    let timer
    clearTimeout(timer)
    if (highlight) {
      timer = setTimeout(resetHighlight, 3000)
    }
    return () => {
      clearTimeout(timer)
    }
  }, [highlight])

  return (
    <Layout>
      <SEO title={title} />
      <Transition
        location={location}
        intro={() => (
          <Splash colour="#FFFFFF">
            <h1>{title}</h1>
          </Splash>
        )}
        introKey={`artistIntro-${artistSlug}`}
        render={handleLink => (
          <>
            <LightboxLink handleLink={handleLink} />
            <Header handleLink={handleLink} />
            <main className="head-offset">
              <div className="project-filter">
                <span className="project-filter__name">{`${title},`}</span>
                <ul className="project-filter__list">
                  {categories &&
                    categories.map(node => {
                      const has = exisitingCategories.find(
                        cat => cat.slug === node.slug
                      )
                      return (
                        has && (
                          <li
                            className={`project-filter__list-item ${
                              node.slug === activeFilter ? "active" : ""
                            }`}
                            onClick={e => handleFilterClick(node.slug)}
                            key={`filter-${node.slug}`}
                          >
                            {node.title}
                          </li>
                        )
                      )
                    })}
                  <li
                    className={`project-filter__list-item ${
                      activeFilter === "all" ? "active" : ""
                    }`}
                    onClick={e => handleFilterClick("all")}
                  >
                    All
                  </li>
                  {biography && (
                    <li
                      className={`project-filter__list-item ${
                        bioOpen ? "active" : ""
                      }`}
                      onClick={e => setBioOpen(true)}
                    >
                      Bio
                    </li>
                  )}
                  {/* if instagram is defined */}
                  {instagramUrl && (
                    <li className={`project-filter__list-item`}>
                      <a
                        href={instagramUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Instagram
                      </a>
                    </li>
                  )}
                  {/* if artist agent email is defined */}
                  {artistAgentEmail && (
                    <li className={`project-filter__list-item`}>
                      <a
                        href={`mailto:${artistAgentEmail}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Email Agent
                      </a>
                    </li>
                  )}
                </ul>
                <h4
                  className={`project-filter__active-client centred ${
                    highlight ? "visible" : "hidden"
                  }`}
                >
                  {highlight && highlight.byline ? (
                    <span
                      dangerouslySetInnerHTML={{ __html: highlight.byline }}
                    />
                  ) : highlight && highlight.client ? (
                    <span>
                      <em>for</em> {highlight && highlight.client}
                    </span>
                  ) : (
                    <span>View Project</span>
                  )}
                </h4>
              </div>

              {bioOpen && (
                <ArtistBio
                  title={title}
                  biography={biography}
                  handleCloseClick={() => setBioOpen(false)}
                />
              )}

              <div
                className={`artist-thumbnails ${activeFilter} ${
                  activeFilter !== "all" ? "big-thumbs" : ""
                }`}
                key={`artist-thumbnails-${activeFilter}`}
              >
                {projects.map((project, projectIndex) => {
                  let videoCount = 0
                  const isFiltered = project.projectTypes.find(
                    type => type.slug === activeFilter
                  )
                  const thumbnails =
                    activeFilter !== "all"
                      ? project.thumbnails
                      : project.gallery

                  return activeFilter === "all" || isFiltered ? (
                    <React.Fragment key={`${project.slug}-${projectIndex}`}>
                      {thumbnails.map((thumb, thumbIndex) => {
                        const showThumb =
                          activeFilter !== "all" ? thumbIndex < 1 : true
                        const thumbClassName = `thumb ${
                          !highlight.slug || project.slug === highlight.slug
                            ? "active"
                            : "inactive"
                        }`
                        const isVideo = thumb.typeHandle === "video"
                        let to = `/project/${artistSlug}/${project.slug}-${project.id}`
                        // if (!activeFilter && i > 0) {
                        //   to = `/${artistSlug}/${project.slug}/#${i+1}`
                        // }
                        if (activeFilter !== "all" && isVideo) {
                          videoCount = videoCount + 1
                        }

                        return thumb.typeHandle === "video" && showThumb ? (
                          <div
                            className={thumbClassName}
                            onMouseEnter={e => setHighlight(project)}
                            onMouseLeave={e => setHighlight(false)}
                            onTouchStart={e => setHighlight(project)}
                            onClick={e => handleLink(to)}
                            key={`${thumb.typeHandle}-${thumb.id}`}
                          >
                            <Vimeo className="proheight" {...thumb} />
                          </div>
                        ) : (
                          showThumb &&
                            thumb.source.map(asset => {
                              return (
                                <div
                                  className={thumbClassName}
                                  onMouseEnter={e => setHighlight(project)}
                                  onMouseLeave={e => setHighlight(false)}
                                  onTouchStart={e => setHighlight(project)}
                                  onClick={e => handleLink(to)}
                                  key={asset.id}
                                >
                                  <Picture
                                    className="proheight"
                                    {...asset}
                                    resize={{
                                      height:
                                        activeFilter !== "all" ? 240 : 160,
                                    }}
                                  />
                                </div>
                              )
                            })
                        )
                      })}
                    </React.Fragment>
                  ) : null
                })}
              </div>
            </main>
          </>
        )}
      />
    </Layout>
  )
}

ArtistPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ArtistPage

export const pageQuery = graphql`
  query ArtistPageQuery($authorId: [Craft_QueryArgument]) {
    craft {
      categories(group: "ProjectTypes") {
        ... on Craft_projectTypes_Category {
          title
          slug
        }
      }
      projects: entries(type: "Projects", authorId: $authorId) {
        ... on Craft_projects_projects_Entry {
          ...ProjectGallery
        }
      }
      artist: entries(type: "artists", authorId: $authorId) {
        ... on Craft_artists_artists_Entry {
          title
          slug
          biography
          instagramUrl
          artistAgentEmail
        }
      }
    }
  }
`
